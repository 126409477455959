import { Component, OnInit } from '@angular/core';
import { Constants } from '../constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  readonly CompanyFullname: string = Constants.CompanyFullname;
  readonly CompanyAddress: string = Constants.CompanyAddress;
  readonly CompanyTIN: string = Constants.CompanyTIN;
  readonly Copyrights: string = Constants.Copyrights;
  readonly ContactEmail: string = Constants.ContactEmail;
  readonly ContactPhone: string = Constants.ContactPhone;
  readonly ContactFacebook: string = Constants.ContactFacebook;
  
  constructor() { }

  ngOnInit(): void {
  }

}
