export const photosFilenames: Array<{ big, small }> = [
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG1.jpg?alt=media&token=a9c7b849-9894-4a2a-bd6f-c9625ca0a3d9',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL1.jpg?alt=media&token=799f83c8-16ad-4388-8238-f753b4c54c42'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG2.jpg?alt=media&token=9d26f541-9ffc-4914-9ce8-e31afbbbba26',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL2.jpg?alt=media&token=31f73214-2468-4dcb-8410-270dfa4b0de5'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG3.jpg?alt=media&token=1ffc540c-132c-49fd-820b-b3021cbea2e5',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL3.jpg?alt=media&token=6c72bd4d-869c-4bb2-95a0-0af33a1a1d61'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG4.jpg?alt=media&token=902bb179-2640-459f-be1d-06890b85f487',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL4.jpg?alt=media&token=fded109c-7a7e-4ce6-a0df-7288bfb5d39c'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG5.jpg?alt=media&token=8895fe3c-93e8-4907-acf8-d95bf9671513',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL5.jpg?alt=media&token=348902f3-2547-48f3-822d-096ad4f9d7bd'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG6.jpg?alt=media&token=74310352-6913-4038-a692-d48721f6efec',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL6.jpg?alt=media&token=78cf4031-dd9c-48ba-b15d-4983d5cf892c'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG7.jpg?alt=media&token=19b9a137-b14b-4a8f-9356-fd69dea966dd',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL7.jpg?alt=media&token=b7a0b96c-b3df-43a0-b600-45af782820c8'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG8.jpg?alt=media&token=b89a3b0e-7f3e-49c7-9107-26dd4845d4d7',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL8.jpg?alt=media&token=7537b2d0-9c89-4228-91e8-e9512fa3e6fb'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG9.jpg?alt=media&token=dd068062-2acf-4da4-b830-1681a89f1a50',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL9.jpg?alt=media&token=86c34575-a931-448b-8f9a-f426fc824021'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG10.jpg?alt=media&token=c793ff08-766b-4069-a2d7-f08d2c829446',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL10.jpg?alt=media&token=37faf0cb-6482-4671-bda2-35135e83d09c'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG11.jpg?alt=media&token=ec501d16-1aff-492d-9b5a-6e5a9999c147',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL11.jpg?alt=media&token=3a5fd5f4-7014-4db4-b7c7-a14c7dea45d2'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG12.jpg?alt=media&token=b57a0785-1731-45ad-b32f-73bba36e8100',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL12.jpg?alt=media&token=f4ffbe8a-3c35-4f68-a232-236841a50a4c'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG13.jpg?alt=media&token=e7c50c29-c68d-4f57-bce1-c3752082994e',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL13.jpg?alt=media&token=589fc23a-3aa6-4be1-82d8-c503c73b22fc'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG14.jpg?alt=media&token=c81825d2-9033-46ab-a4c0-9937f28646c5',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL14.jpg?alt=media&token=3ae7abb6-348d-4bd6-9506-52c42119445c'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG15.jpg?alt=media&token=fa4bfa9e-a255-474c-a591-adb1445c40a9',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL15.jpg?alt=media&token=2806b532-2c6f-441b-8735-6c91455d769e'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG16.jpg?alt=media&token=b5a7f2e8-59ca-4c33-afcb-2eb164ea419a',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL16.jpg?alt=media&token=1893bb75-2ef3-4dfe-a9e7-17e846f392c1'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG17.jpg?alt=media&token=c76327d1-6b4d-4699-880f-173966f79db5',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL17.jpg?alt=media&token=739a75e7-c93d-43e3-833e-0667d3220c78'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG18.jpg?alt=media&token=ec92c1d7-e4cd-4a14-bed1-8fc55a7bb52a',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL18.jpg?alt=media&token=0fc86aff-8162-4334-b225-17b989f9c7c3'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG19.jpg?alt=media&token=329e987c-15bc-42e1-866e-43bc79b934a0',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL19.jpg?alt=media&token=8a0031d2-4c95-4bc1-9f92-8f49b6ac267e'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG20.jpg?alt=media&token=738e3a6f-00c8-4c0e-bd51-87f55f6d789c',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL20.jpg?alt=media&token=b091c0fa-7439-401b-9f71-370bee1a8ed0'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/BIG21.jpg?alt=media&token=06013274-74f3-464f-a18e-24eddc42b3ac',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL21.jpg?alt=media&token=26771ed8-1e26-498c-9c7d-659a04d1e34c'
  },
  {
    big: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/SMALL22.jpg?alt=media&token=c8c32b6a-b104-475f-ac7f-9423bf6a25cd',
    small: 'https://firebasestorage.googleapis.com/v0/b/geopalfundamenty-9fa81.appspot.com/o/poprawione%20smal%2FSMALL22.jpg?alt=media&token=cdb0ed79-d2ce-4f39-a30e-9e3282250d91'
  },
]
