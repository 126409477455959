import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Photo from 'src/app/realizations/models/photo.model';
import { RealizationsService } from 'src/app/realizations/realizations.service';

@Component({
  selector: 'app-big-photo',
  templateUrl: './big-photo.component.html',
  styleUrls: ['./big-photo.component.scss'],
})
export class BigPhotoComponent implements OnInit {
  currentImage: Photo;
  readonly KEY_ARROW_LEFT_CODE: number = 37;
  readonly KEY_ARROW_RIGHT_CODE: number = 39;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private galleryService: RealizationsService
  ) {
    this.currentImage = data.photo;
    document.addEventListener('keydown', (e) =>
      this.handleKeyPressed(e.keyCode)
    );
  }

  ngOnInit(): void {}

  onNext(): void {
    this.currentImage = this.galleryService.getNext(this.currentImage);
    setTimeout(() => {
      document.getElementById('image').style.backgroundSize = 'cover';
      document.getElementById('image').style.backgroundPosition = 'center';
    }, 100);
  }

  onPrevious(): void {
    this.currentImage = this.galleryService.getPrevious(this.currentImage);
    setTimeout(() => {
      document.getElementById('image').style.backgroundSize = 'cover';
      document.getElementById('image').style.backgroundPosition = 'center';
    }, 100);
  }

  setPosition(): void {
    document.getElementById('image').style.backgroundPosition = 'center';
  }

  handleKeyPressed(keycode): void {
    switch (keycode) {
      case this.KEY_ARROW_LEFT_CODE:
        this.onPrevious();
        break;
      case this.KEY_ARROW_RIGHT_CODE:
        this.onNext();
        break;
      default:
        return;
    }
  }
}
