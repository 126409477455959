import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('src/app/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'offer',
    loadChildren: () =>
      import('src/app/offer/offer.module').then((m) => m.OfferModule),
  },
  {
    path: 'realizations',
    loadChildren: () =>
      import('src/app/realizations/realizations.module').then((m) => m.RealizationsModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('src/app/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: '', redirectTo: '/home', pathMatch: 'prefix'
  },
  {
    path: '**', loadChildren: () =>
      import('./home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
