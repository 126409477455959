import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  logoUrl: string = Constants.LogoSrc;
  sidenavFirst: boolean = true;
  sidenavOpen: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  toggleSidenav(): void {
    if (this.sidenavFirst) {
      this.sidenavFirst = false;
    }

    this.sidenavOpen = !this.sidenavOpen;
  }
}
