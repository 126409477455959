export default class Photo {
    id: number;
    bigPhotoUrl: string;
    smallPhotoUrl: string;
  
    constructor(accessTokenSmall: string, accessTokenBig: string, id: number) {
      this.id = id;
      this.bigPhotoUrl = accessTokenBig;
      this.smallPhotoUrl = accessTokenSmall;
    }
  }