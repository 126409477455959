import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'geopal';
  @ViewChild('card__inner') card: ElementRef;

  doSth() {
    console.log('helo');
    this.card.nativeElement.classList.toggle('is-flipped');
  }


}
