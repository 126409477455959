<!-- <div class="view-wrapper"> -->
  <app-navbar></app-navbar>
  <router-outlet></router-outlet>
  <!-- <div class="card" id="card" #card (click)="doSth()">
    <div class="card__inner" id="card__inner" #card__inner>
      <div class="card__face card__face--front">
        <h2>Developer card</h2>
      </div>
      <div class="card__face card__face--back">
        <div class="card__content">
          <div class="card__header">
            <img src="" alt="" class="pp">
            <h2>This is some h2</h2>
          </div>
        </div>
        <div class="card__body">
          <h3>This is some h3</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
            Nesciunt consequatur dolorem dolorum id, temporibus cum quam 
            unde labore ipsam culpa quaerat sed nam quisquam doloremque 
            excepturi aperiam officiis minus itaque!
          </p>
        </div>
      </div>
    </div>
  </div> -->
  <app-footer></app-footer>
<!-- </div> -->