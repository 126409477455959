<div class="dialog--big-photo">
  <mat-dialog-content class="dialog__content">
    <div class="image-wrapper">
      <div #image id="image"
        *ngIf="currentImage.bigPhotoUrl"
        [style.background]="'url(' + currentImage.bigPhotoUrl + ')'"
        [style.background-size]="'cover'"
        [style.background-position]="'center'"
        class="image"
      ></div>
    </div>
  </mat-dialog-content>
  <button
    class="dialog__button dialog__button--previous"
    (click)="onPrevious()"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button class="dialog__button dialog__button--next" (click)="onNext()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <button class="dialog__button dialog__button--close" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
</div>
