<div class="footer row justify-content-center">
  <div class="footer__company mx-5">
    <p class="footer__company-fullname"><strong>{{CompanyFullname}}</strong></p>
    <p class="footer__company-address">{{CompanyAddress}}</p>
    <p class="footer__company-tin">NIP: {{CompanyTIN}}</p>
    <p class="footer__company-copyrights">{{Copyrights}}</p>
  </div>
  <div class="footer__contact mx-5">
    <span class="footer__contact-item footer__contact-email">
      <mat-icon class="home__arrow-right">email</mat-icon>
      {{ContactEmail}}
    </span>
    <span class="footer__contact-item footer__contact-phone">
      <mat-icon class="home__arrow-right">call</mat-icon>
      {{ContactPhone}}
    </span>
    <a href="https://www.facebook.com/palowanie"
       target="blank">
      <span class="footer__contact-item footer__contact-facebook">
        <mat-icon class="home__arrow-right">facebook</mat-icon>
        {{ContactFacebook}}
      </span>
    </a>
  </div>
</div>