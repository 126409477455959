<div class="navbar">
    <div class="hamburger"
        [ngClass]="{'hamburger--open' : sidenavOpen}"
        (click)="toggleSidenav()">
        <div class="bar" ></div>
    </div>
    <nav class="sidenav" 
    [ngClass]="{'sidenav--init' : sidenavFirst,
                'sidenav--open' : (sidenavOpen && !sidenavFirst),
                'sidenav--closed' : (!sidenavOpen && !sidenavFirst)}">
        <button class="sidenav__button"
                routerLink="/home"
                (click)=toggleSidenav()>
            <div class="inner-frame"></div>
            Strona główna
        </button>
        <button class="sidenav__button"
                routerLink="/offer"
                (click)=toggleSidenav()>
            <div class="inner-frame"></div>
            Oferta
        </button>
        <button class="sidenav__button"
                routerLink="/realizations"
                (click)=toggleSidenav()>
            <div class="inner-frame"></div>
            Realizacje
        </button>
        <button class="sidenav__button"
                routerLink="/contact"
                (click)=toggleSidenav()>
            <div class="inner-frame"></div>
            Kontakt
        </button>
    </nav>
    <div class="navbar__logo"
         [routerLink]="['']">
        <img src="{{ logoUrl }}"
             class="navbar__logo-img">
    </div>
    <nav class="navbar__menu">
        <a class="navbar__option invisible-in-mobile"
             routerLink="/home"
             routerLinkActive="navbar__option--active">
            Strona główna
        </a>
        <a class="navbar__option"
             routerLink="/offer"
             routerLinkActive="navbar__option--active">
            Oferta
        </a>
        <a class="navbar__option"
             routerLink="/realizations"
             routerLinkActive="navbar__option--active">
            Realizacje
        </a>
        <a class="navbar__option"
             routerLink="/contact"
             routerLinkActive="navbar__option--active">
            Kontakt
        </a>
    </nav>
</div>