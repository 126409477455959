export abstract class Constants {
  public static readonly LogoSrc: string = 'assets/images/logo-cropped.png';
  public static readonly HomePageBackground1Src: string = 'assets/images/bg6-21-filter.jpg';
  public static readonly HomePageBackground2Src: string = 'assets/images/b2-21.jpg';
  public static readonly HomePageBackground3Src: string = 'assets/images/bg4-21-filter.jpg';
  public static readonly HomePageSmallerTexts: string[][] = [
    ["Kompleksowa obsługa", "od projektu do realizacji"],
    ["Elastyczne rozwiązania", "dopasowane do inwestycji"],
    ["Małe i duże projekty", "bo każdy klient jest ważny"]
  ];
  public static readonly CompanyFullname: string = 'GEOPAL Fundamenty Sp. z o. o.';
  public static readonly CompanyAddress: string = 'ul. Poniatowskiego 1b, 43-200 Pszczyna';
  public static readonly CompanyTIN: string = '6381841000';
  public static readonly Copyrights: string = 'Copyrights © 2020 GEOPAL Fundamenty';
  public static readonly ContactEmail: string = 'biuro@geopal-fundamenty.pl';
  public static readonly ContactPhone: string = '+48 530 771 774';
  public static readonly ContactFacebook: string = 'facebook.com/palowanie';
  public static readonly FormspreeLink: string = 'https://formspree.io/f/mdopprpe';

  /* Offer */
  public static readonly OfferPiles: string = 'Roboty palowe';
  public static readonly OfferPilesItems: string[] = [
    'pale i kolumny różnych technologii',
    'mikropale różnych systemów',
    'iniekcje',
    'badania nośności i ciągłości pali',
  ];
  public static readonly OfferPilesBackgroundSrc: string ='assets/images/offer-piles-vert.jpg';

  public static readonly OfferExcavations: string = 'Zabezpieczenia wykopów';
  public static readonly OfferExcavationsItems: string[] = [
      'ścianki berlińskie',
      'stalowe ścianki szczelne',
      'palisady z pali i mikropali',
      'monitoring oddziaływania robót na otoczenie',
  ];
  public static readonly OfferExcavationsBackgroundSrc: string ='assets/images/offer-excavations-vert.jpg';

  public static readonly OfferDocumentation: string = 'Dokumentacja projektowa';
  public static readonly OfferDocumentationItems: string[] = [
    'projekty wykonawcze',
    'projekty technologiczne',
    'projekty próbnych obciążeń pali',
    'projekty monitoringu oddziaływania',
  ];
  public static readonly OfferDocumentationBackgroundSrc: string ='assets/images/offer-documentation-vert.jpg';

  public static readonly OfferConsulting: string = 'Doradztwo i konsulting';
  public static readonly OfferConsultingItems: string[] = [
    'analiza budżetu',
    'dobór technologii',
    'postępowania przetargowe',
    'nadzór nad robotami',
  ];
  public static readonly OfferConsultingBackgroundSrc: string ='assets/images/offer-consulting-vert.jpg';

}
