import { Injectable } from '@angular/core';
import { photosFilenames } from '../data/images';
import Photo from './models/photo.model';

@Injectable({
  providedIn: 'root'
})
export class RealizationsService {
  private photos: Array<Photo> = photosFilenames.map((p, i) => new Photo(p.small, p.big, i));

  constructor() { }

  getNext(current: Photo): Photo {
    return current.id != this.photos.length - 1 ? this.photos[current.id + 1] : this.photos[0];

  }

  getPrevious(current: Photo): Photo {
    return current.id != 0 ? this.photos[current.id - 1] : this.photos[this.photos.length - 1];
  }

  getPhotos(): Array<Photo> {
    return this.photos;
  }
}
